<template>
  <v-container>
    <template v-if="this.user == null">
      <h1>Log in to Genesys</h1>
      <v-btn @click="login" class="btn btn-primary">Log in with Genesys</v-btn>
      <br />
    </template>
    <template v-else>
        <div class="d-flex mb-6 px-2">
          <img src="@/assets/2022-cbr1000rr-r-fireblade-sp-pearl_white.jpg" width="750">
          <img src="@/assets/20222_zx_10rr.png" width="750">
          
        </div>

    </template>
  </v-container>
</template>
  
  <script>
import Vue from "vue";
import config from "../config";

export default {
  name: "HelloWorld",
  data() {
    return {};
  },
  methods: {
    login() {
      Vue.axios.get(`${config.services.host}/login`).then((url) => {
        console.log(url);
        window.location.href = url.headers.location;
      });
    },
    logout() {
      this.$store.commit("setUser", null);
      this.$router.push({ name: "HelloWorld" });
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  created() {
      Vue.axios
      .get(`${config.services.host}/me`, {
        withCredentials: true,
        credentials: "include",
        })
        .then((response) => {
          this.$store.commit("setUser", response.data);
          this.$store.commit("mutateAccessToken")
        });
  },
};
</script>
  <style scoped>
.svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
}
.partzilla {
  fill: currentColor;
  transform: scale(20);
}
</style>

