<template>
  <v-container>
    <v-data-table
      :headers="headers"
      :items="metricTable"
      :expanded.sync="expanded"
      item-key="id"
      show-expand
      class="elevation-1"
      @click:row="(item,slot) => slot.expand(!slot.isExpanded)"
    >
      <template v-slot:top>
        <v-toolbar flat color="gray">
          <v-toolbar-title>Add User Metric Data</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{item}">
        <v-btn @click="addMetric(item)">Add User Metric</v-btn>
      </template>
      <template v-slot:expanded-item="{headers, item}">
        <td :colspan="headers.length">
          <v-data-table
            :headers="nestHeaders"
            :items="item.metric"
            disable-pagination
            :hide-default-footer="true"
            >
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <v-btn @click="submit">submit</v-btn>
    <v-dialog v-model="dialog" max-width="1000px">
      <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="editedItem.name"
                label="Name"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-select
                v-model="editedItem.metric"
                :items="metrics"
                item-text="name"
                item-value="id"
                return-object
                label="Metric ID"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="editedItem.dateOccurred"
                    label="Date Occurred"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="editedItem.dateOccurred"
                  no-title
                  scrollable
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="editedItem.value"
                label="Value"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="editedItem.count"
                label="Count"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          Cancel
        </v-btn>
        <v-btn color="blue darken-1" text @click="save">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import config from "../config";
import Vue from "vue";
import moment from "moment";

export default {
  name: "EditMetrics",
  data() {
    return {
      expanded: [],
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "",
          value: "actions",
          active: true,
          sortable: false,

        }
      ],
      nestHeaders: [
        {
          text: "Metric",
          align: "start",
          sortable: true,
          value: "metric.name",
        },
        {
          text: "Date Occurred",
          align: "start",
          sortable: true,
          value: "dateOccurred",
        },
        {
          text: "Value",
          align: "start",
          sortable: true,
          value: "value",
        },
        {
          text: "Count",
          align: "start",
          sortable: true,
          value: "count",
        },
      ],
      metrics: [],
      date: null,
      menu: false,
      users: [],
      editedItem: {
        name: "",
        count: 0,
        dateOccurred: null,
        id: null,
        value: null,
        metric: {
          metricId: null,
          metricName: null,
        },
      },
      metricTable: [],
      selectedItem: false,
      dialog: false,
      editedIndex: -1,
      payload: {
        items: [],
      },
    };
  },
  computed: {
    // accessToken() {
    //   return this.$store.getters.getAccess;
    // },
    // user() {
    //   return this.$store.getters.getUser;
    // }
  },
  watch: {
    users: {
      handler(newVal, oldVal) {
        this.users?.forEach((element) => {
          let metricPerson = {
            id: element.id,
            name: element.name,
            metric: [],
          };
          this.metricTable.push(metricPerson);
        });
      },
      deep: true,
    },
  },
  mounted() {
    Vue.axios
      .get(`${config.services.host}/metrics`, { withCredentials: true, credentials: 'include' })
      .then((response) => {
        this.metrics = response.data.entities;
      });
    this.users = this.$route.params.data;
  },
  methods: {
    selectItem(item) {
      this.selectedItem = item;
    },
    addMetric(item) {
      this.dialog = true;
      this.editedIndex = this.metricTable.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedItem.name = item.name;
      this.editedItem.id = item.id;
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(
          this.metricTable[this.editedIndex].metric,
          this.editedItem
        );
        let updatedItem = {
          user: this.editedItem.id,
          metric: this.editedItem.metric,
          dateOccurred: moment(this.editedItem.dateOccurred).toISOString(),
          value: parseFloat(this.editedItem.value),
          count: parseInt(this.editedItem.count, 10),
        };
        let newItem = {
          userId: this.editedItem.id,
          metricId: this.editedItem.metric.id,
          dateOccurred: moment(this.editedItem.dateOccurred).toISOString(),
          value: parseFloat(this.editedItem.value),
          count: parseInt(this.editedItem.count, 10),
        };
        this.payload.items.push(newItem);
        this.metricTable[this.editedIndex].metric.push(updatedItem);
      } else {
        this.metricTable[this.editedIndex].metric.push(this.editedItem);
      }
      this.close();
    },
    submit() {
      Vue.axios.post(`${config.services.host}/metrics/post`, this.payload, {withCredentials: true, credentials: 'include'}).then((response) => {
        console.log("success")
      }).catch(err => {
        // Handle Error Here
        console.error(err);
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({});
        this.editedIndex = -1;
      });
    },
    open() {
      console.log("open");
    },
    log(item) {
      console.log(metric);
    },
    onExpand({ item, value }) {
      return item;
    },
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.profile_pic {
  width: 100px;
}
</style>\
