import Vue from 'vue';
import Router from 'vue-router';
import HelloWorld from "../components/HelloWorld.vue";
import EditMetrics from "../components/EditMetrics.vue";
import SelectMetrics from "../components/SelectMetrics.vue";
import help from "../components/help.vue";
Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/select-metrics',
            name: 'SelectMetrics',
            component: SelectMetrics,
            meta: {
                title: 'Select Users for Metric Updates',
            },
            children: [
               
            ]
        },
        {
            path: '/edit-metrics',
            name: 'EditMetrics',
            component: EditMetrics,
            meta: {
                title: 'Edit User Metrics',
            }
        },
        {
            path: '/',
            name: 'HelloWorld',
            component: HelloWorld,
            meta: {
                title: 'Hello World',
            }
        },
        {
            path: '/help',
            name: 'KnowledgePortal',
            component: help,
            meta: {
                title: 'Knowledge Portal',
            }
        }
       
    ]
});

export default router;