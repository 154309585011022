<template>
  <v-container>
    <template v-if="this.user">
      <h1>Hi there, {{ this.user.name }}</h1>
      <p>Email address: {{ this.user.email }}</p>
      <v-dialog v-model="selectUserDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Privacy Policy
          </v-card-title>

          <v-card-text>
            Select all users you wish to update Gamification Metric Scores.
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="selectUserDialog = false">
              I accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div>
        <!-- <v-data-table
          :headers="headers"
          :items="metricTable"
          item-key="name"
          class="elevation-1"
          v-model="selected"
          show-select
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>My CRUD</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> Reset </v-btn>
          </template>
          <div class="text-center pt-2"></div>
        </v-data-table> -->
      <v-list shaped>
      <v-list-item-group
        v-model="selected"
        active-class="red--text"
        multiple
      >
        <template v-for="(item, i) in people">
          <v-list-item :key="`item-${i}`" :value="item">
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="item.name"></v-list-item-title>

                <!-- <v-list-item-subtitle
                  class="text--primary"
                  v-text="item.headline"
                ></v-list-item-subtitle> -->

                <!-- <v-list-item-subtitle v-text="item.subtitle"></v-list-item-subtitle> -->
              </v-list-item-content>

              <v-list-item-action>
                <v-icon
                  v-if="!active"
                  color="grey lighten-1"
                >
                  mdi-star-outline
                </v-icon>

                <v-icon
                  v-else
                  :selected=item.id
                  color="yellow darken-3"
                >
                  mdi-star
                </v-icon>
              </v-list-item-action>
            </template>
          </v-list-item>

          
        </template>
      </v-list-item-group>
    </v-list>
       <v-btn @click="submit"> Submit </v-btn>
      </div>
    </template>
    <template v-else>
      <h1>Log in to Genesys</h1>
      <v-btn @click="login" class="btn btn-primary">Log in with Genesys</v-btn
      ><br />
    </template>
  </v-container>
</template>

<script>
import config from "../config";
import Vue from "vue";
import moment from "moment";
import { mdiCursorDefaultClick, mdiShieldStar } from "@mdi/js";

export default {
  name: "HelloWorld",
  data() {
    return {
      headers: [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "MetricId",
          align: "start",
          sortable: true,
          value: "metric.name",
        },
        {
          text: "Date Occurred",
          align: "start",
          sortable: true,
          value: "dateOccurred",
        },
        {
          text: "Value",
          align: "start",
          sortable: true,
          value: "value",
        },
        {
          text: "Count",
          align: "start",
          sortable: true,
          value: "count",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
        },
      ],
      selected: [],
      selectUserDialog: false,
      email: "",
      menu: false,
      modal: false,
      people: [],
      metricTable: [],
      totalItems: 0,
      metrics: [],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      date: null,
      editedItem: {
        name: "",
        count: 0,
        dateOccurred: null,
        id: null,
        value: null,
        metric: {
          metricId: null,
          metricName: null,
        },
      },
      payload: {
        items: [],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    accessToken() {
      return this.$store.getters.getAccess;
    },
    refreshToken() {
      return this.$store.getters.getRefresh;
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    selectUserDialog(val) {
      val || this.close();
    },

    people: {
      handler(newVal, oldVal) {
        this.people.forEach((element) => {
          let metricPerson = {
            id: element.id,
            name: element.name,
            metric: {
              metricId: null,
              metricName: null,
            },
            dateOccurred: null,
            value: null,
            count: null,
          };
          this.metricTable.push(metricPerson);
        });
      },
      deep: true,
    },
  },
  mounted() {
    document.onreadystatechange = () => {
      if(document.readyState == "complete") {
      }
    }
  },
  methods: {
    login() {
      Vue.axios.get(`${config.services.host}/login`).then((url) => {
        console.log(url);
        window.location.href = url.headers.location;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.metricTable[this.editedIndex], this.editedItem);
        let updatedItem = {
          userId: this.editedItem.id,
          metricId: this.editedItem.metric.id,
          dateOccurred: moment(this.editedItem.dateOccurred).toISOString(),
          value: parseFloat(this.editedItem.value),
          count: parseInt(this.editedItem.count, 10),
        };
        this.payload.items.push(updatedItem);
      } else {
        this.metricTable.push(this.editedItem);
      }
      this.close();
    },
    submit() {
      let data = this.selected;
      this.$router.push({ name: "EditMetrics", params: {data}});
    },
    fetchItems() {
      Vue.axios
        .get(`${config.services.host}/members`, { withCredentials: true, credentials: 'include' })
        .then((response) => {
          this.people = response.data.entities;
        });
      Vue.axios
        .get(`${config.services.host}/metrics`, { withCredentials: true, credentials: 'include' })
        .then((response) => {
          this.metrics = response.data.entities;
        });
    },
    cancel() {
      console.log("cancel");
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({});
        this.editedIndex = -1;
      });
    },
    open() {
      console.log("open");
    },
    editItem(item) {
      this.editedIndex = this.metricTable.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
  },
  created() {
    Vue.axios
      .get(`${config.services.host}/me`, { withCredentials: true, credentials: 'include' })
      .then((response) => {
        this.$store.commit("setUser", response.data);
      })
      .finally(() => {
        if (this.user) {
          this.fetchItems();
        }
      });
  },
};
</script>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.profile_pic {
  width: 100px;
}
</style>\
