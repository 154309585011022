<template>
  <v-app>
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <v-btn icon id="no-background-hover" to="/">
          <span class="group pa-2">
            <v-icon class="partzilla">$vuetify.icons.custom</v-icon>
          </span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <template v-if="this.user != null">
        <div>
          <router-link to="/select-metrics" tag="button">Edit User Metrics</router-link>
        </div>
      </template> 
      <v-spacer></v-spacer>
      <template v-if="this.user == null">
        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-larg v-on="on">
              <v-avatar size="36px" color="black"> </v-avatar>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <v-avatar color="brown"> </v-avatar>
                <h3>LOGIN</h3>
                <p class="caption mt-1">Login</p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text> Edit Account </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click="help"> Knowledge Portal </v-btn>
                <v-btn depressed rounded text> Logout </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </template>
      <template v-else>
        <v-menu bottom min-width="200px" rounded offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon x-larg v-on="on">
              <template v-if="user.hasOwnProperty('images')">
                <v-avatar>
                  <img :src="user.images[5].imageUri" />
                </v-avatar>
              </template>
              <template v-else>
                <v-icon> mdi-account </v-icon>
              </template>
            </v-btn>
          </template>
          <v-card>
            <v-list-item-content class="justify-center">
              <div class="mx-auto text-center">
                <h3>{{ user.name }}</h3>
                <p class="caption mt-1">
                  {{ user.email }}
                </p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text> Edit Account </v-btn>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed rounded text @click="logout"> Logout </v-btn>
                <v-btn depressed rounded text @click="help"> Knowledge Portal </v-btn>
              </div>
            </v-list-item-content>
          </v-card>
        </v-menu>
      </template>
    </v-app-bar>
    
    <v-main>
      <v-container fluid>

        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",

  head: {
    script:[
      {type:"text/javascript", charset:"utf-8">
        (function (g, e, n, es, ys) {
          g['_genesysJs'] = e;
          g[e] = g[e] || function () {
            (g[e].q = g[e].q || []).push(arguments)
          };
          g[e].t = 1 * new Date();
          g[e].c = es;
          ys = document.createElement('script'); ys.async = 1; ys.src = n; ys.charset = 'utf-8'; document.head.appendChild(ys);
        })(window, 'Genesys', 'https://apps.mypurecloud.com/genesys-bootstrap/genesys.min.js', {
          environment: 'use1',
          deploymentId: '8b8e5786-b836-4f7e-9412-2c67f255bacc'
        })
      }
    ]
  },

  components: {
    HelloWorld,
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    console.log(process.env.VUE_APP_BACKEND)

  },
  methods: {
    logout() {
      this.$store.commit("setUser", null);
      this.$router.push({ name: "HelloWorld" });
    },
    help() {
      this.$router.push({ name: "KnowledgePortal"})
    }
  },

  data: () => ({
    //
  }),
};
</script>
<style scoped>
.svg-container {
  position: relative;
  height: 0;
  width: 100%;
  padding: 0;
  padding-bottom: 100%;
}
.partzilla {
  fill: currentColor;
  transform: scale(10);
}

</style>