<template>
    <v-containter>
        
        <div id="genesys-support-center"></div>

    </v-containter>
        
</template>

<script>
import Vue from "vue";
import config from "../config";

export default {
  name: "help",
  data() {
    return {};
  }
}
</script>

<style>



</style>