import Vue from 'vue';
import Vuetify from 'vuetify';
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Icon from '@/components/Icon.vue'
import colors from 'vuetify/lib/util/colors';


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: '#E60C0C',
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3
    
      }
    },
  dark: true
  },
  icons: {
    values: {
      custom: {
        component: Icon
      }
    }
  }
});
